<template>
  <div>
    <el-dialog :visible="show" title="选择门店" @close="close" width="900px" custom-class="dialogpad">
      <div class="flexRow" style="flex-wrap:wrap">
        <div class="flexRow keybox">
          <div class="keyFont">关键字：</div>
          <el-input v-model="KeyWords" style="width:200px" placeholder="门店名称、门店编号"></el-input>
        </div>
        <div class="flexRow keybox">
          <div class="keyFont">门店标签：</div>
          <el-select v-model="tag" placeholder="请选择" filterable clearable style="width: 160px">
						<el-option :key="-1" label="全部" :value="null"></el-option>
						<el-option v-for="(item,index) in tagList" :key="index" :label="item.TagName" :value="item.Id"></el-option>
					</el-select>
        </div>
        <div class="flexRow keybox">
          <div class="keyFont">门店状态：</div>
          <el-select v-model="state" placeholder="请选择" filterable clearable style="width: 160px">
						<el-option :key="-1" label="全部" :value="null"></el-option>
						<el-option v-for="(item,index) in stateList" :key="index" :label="item.label" :value="item.value"></el-option>
					</el-select>
        </div>
        <el-button type="primary" @click="search" style="margin-bottom:10px">查询</el-button>
      </div>
      <div style="margin-top:10px">
        <el-table :data="tablelist" v-loading="tableloading" max-height="500" :row-key="getrowkey" ref="table" @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="45" :reserve-selection="true"></el-table-column>
          <el-table-column prop="ShopNo" label="门店编码"></el-table-column>
          <el-table-column prop="ShopName" label="门店名称"></el-table-column>
          <el-table-column prop="" label="门店状态">
            <template slot-scope="scope">
              {{scope.row.IsOpen?'正常营业':'停用'}}
            </template>
          </el-table-column>
          <el-table-column prop="ShopTagName" label="门店标签"></el-table-column>
        </el-table>
      </div>
      <div class="flexRow flexRowbetween" style="margin-top:10px;text-align:right" v-if="total">
        <div>
          <el-checkbox :value="allchoose" @change="allchange" style="margin-left:15px">当前页全选</el-checkbox>
        </div>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="pagesize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </div>
      <div class="flexRow flexRowbetween" style="margin-top:10px">
        <div></div>
        <div class="flexRow">
          <div style="margin-right:15px">
            已选中<span style="color:#f56c6c">{{chooseList.length}}</span>家
          </div>
          <el-button type="primary" @click="confirm">确定</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>

import {
  shoptaglist,
  employeeshoplist
} from '@/api/api.js';
import {
  StoreManageList
} from '@/api/TurnTomySelf.js';
export default {
  props:{
    visible:{
      type:Boolean,
      default:false
    },
    selectDate:{
      type:Array,
      default:()=>{
        return []
      }
    }
  },
  data () {
    return {
      show:false,
      KeyWords:'',
      tag:null,
      tagList:[],
      state:null,
      stateList:[
        {value:true,label:'正常营业'},
        {value:false,label:'停用'},
      ],
      tablelist:[],
      tableloading:false,
      currentPage:1,
      pagesize:10,
      total:null,
      chooseList:[],

      first:false
    }
  },
  computed: {
    allchoose(){
      if(this.tablelist&&this.tablelist.length){
        return this.tablelist.every((v)=>{
          return this.chooseList.some((x)=>{
            return x.Id==v.Id
          })
        })
      }
    }
  },
  watch: {
    visible(val){
      this.show = val
      if(val){
        this.first = true
        this.chooseList = this.selectDate
        this.getTagList()
        this.getlist()
        // console.log(this.chooseList.map(v=>v.ShopName),22)
      }
    },
    // selectDate(val){
    //   this.chooseList = val
    // }
  },
  methods: {
    search(){
      this.currentPage=1
      this.getlist()
    },
    confirm(){
      this.$emit('confirm',this.chooseList)
    },
    allchange(){
      this.$refs.table.toggleAllSelection()
    },
    getrowkey(row){
      return row.Id
    },
    handleSelectionChange(list){
      this.chooseList = list
      // console.log(list.map(v=>v.ShopName))
    },
    handleSizeChange(e){
      this.currentPage = 1
      this.pagesize = e
      this.getlist()
    },
    handleCurrentChange(e){
      this.currentPage = e
      this.getlist()
    },
    async getTagList() {
      const res = await shoptaglist();

      if (res.IsSuccess) {
        this.tagList = res.Result || [];
      }

    },
    async getlist(){
      try{
        this.tableloading = true
        let data = {
          KeyWords:this.KeyWords,
          ShopTagId:this.tag,
          IsOpen:this.state,
          Skip:(this.currentPage-1)*this.pagesize,
          Take:this.pagesize
        }
        let res = await StoreManageList(data)
        if(res.IsSuccess){
          this.tablelist = res.Result.Results
          this.total = res.Result.Total
          
          // if(this.chooseList&&this.chooseList.length){
            if(!this.first) return
            
            // this.search()//第一次打开勾选的数据不会回显 在调一次查询接口
            // this.$nextTick(()=>{
              this.chooseList.map(v=>{
                // if(this.tablelist.some(x=>x.Id==v.Id)){
                  // console.log(v)
                  this.$refs.table.toggleRowSelection(v,true)
                // }
              })
              
              // setTimeout(()=>{
                this.chooseList = this.selectDate
                this.first = false
              // },500)
            // })
          // }
        }
      }finally{
        this.tableloading = false
      }
    },
    close(){
      this.KeyWords = ''
      this.tag = null
      this.state = null
      this.chooseList = []
      this.currentPage = 1
      this.pagesize = 10
      this.first = false
      this.$refs.table.clearSelection()
      // console.log(this.chooseList,123)
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.flexRow{
  display: flex;
  align-items: center;
}
.flexRowbetween{
  justify-content: space-between;
}
.keybox{
  margin: 0px 16px 10px 0px;
  .keyFont{
    font-size: 14px;
    color: #606266;
    font-weight: bold;
    white-space: nowrap;
  }
}
</style>
<style lang="less">
.dialogpad .el-dialog__body{
  padding: 20px;
}
</style>